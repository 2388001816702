import React from 'react';
import logo from '../assets/images/logo/logo.svg';
import './Home.css';

function Home() {
  return (
    <div className="Home">
      <header className="Home-header">
        <img src={logo} className="Home-logo" alt="logo" />
        <p>This is the landing page for zippiux skillhub.</p>
        <a
          className="Home-link"
          href="https://skillhub.zippiux.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          View live version
        </a>
      </header>
    </div>
  );
}

export default Home;
